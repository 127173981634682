import { FormControl, FormHelperText } from '@mui/material'
import { Controller } from 'react-hook-form'
import { Editor } from '@tinymce/tinymce-react'

import useStyles from './styles'

const ReactHookFormEditor = ({
  name,
  control,
  errorMessage,
  fullWidth = true,
  image,
  height = 250,
  fullscreen = false,
  pagebreak = false,
  ...props
}) => {
  const classes = useStyles()
  const isError = Boolean(errorMessage)
  const removePTagOnTyping = editor => {
    editor.on('BeforeSetContent', event => {
      event.content = event.content.replace(/<\/?p[^>]*>/g, '')
    })

    editor.on('PostProcess', event => {
      if (event.get) {
        event.content = event.content.replace(/<\/?p[^>]*>/g, '')
      }
    })
  }

  return (
    <FormControl fullWidth={fullWidth} margin="dense">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          const handleEditorChange = editor => onChange(editor)

          return (
            <Editor
              apiKey={process.env.NEXT_PUBLIC_TINY_MCE_KEY}
              value={value}
              onEditorChange={handleEditorChange}
              onInit={(evt, editor) => removePTagOnTyping(editor)}
              init={{
                // min_height: 320,
                // plugins: 'image textpattern lists table ',
                // toolbar: 'undo redo | image',
                encoding: 'UTF-8',
                entity_encoding: 'raw',
                height: height,
                menubar: false,
                plugins: [
                  'image',
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'charmap',
                  // 'print',
                  'preview',
                  'anchor',
                  'help',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'insertdatetime',
                  'media',
                  'table',
                  // 'paste',
                  'wordcount',
                  'fullscreen',
                  'pagebreak'
                ],
                toolbar: `undo redo | ${
                  fullscreen ? 'fullscreen' : ''
                } | formatselect | bold italic underline | fontsizeselect | table | \
                      alignleft aligncenter alignright alignjustify | \
                      lineheight bullist numlist outdent indent ${
                        pagebreak ? 'pagebreak' : ''
                      } | forecolor backcolor |  ${
                  image ? 'image' : ''
                } | help`,
                theme_advanced_resizing: true,
                pagebreak_separator:
                  '<div style="page-break-after:always"><span style="display:none">&nbsp;</span></div>', // originally <!-- pagebreak --> but we adapted to class in backend

                branding: false,
                elementpath: false,
                body_class: classes.editor,
                automatic_uploads: true,
                file_picker_types: 'image',
                file_picker_callback: function (cb) {
                  const input = document.createElement('input')
                  input.setAttribute('type', 'file')
                  input.setAttribute('accept', 'image/*')
                  input.onchange = function (event) {
                    const file = event.target.files[0]
                    const reader = new FileReader()
                    reader.onload = function () {
                      const id = 'blobid' + new Date().getTime()
                      const blobCache =
                        // eslint-disable-next-line no-undef
                        tinymce.activeEditor.editorUpload.blobCache
                      const base64 = reader.result.split(',')[1]
                      const blobInfo = blobCache.create(id, file, base64)
                      blobCache.add(blobInfo)

                      cb(blobInfo.blobUri(), { title: file.name })
                    }
                    reader.readAsDataURL(file)
                  }

                  input.click()
                },
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
              {...props}
            />
          )
        }}
      />
      {isError && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

export default ReactHookFormEditor
