import { Controller } from 'react-hook-form'
import {
  FormHelperText,
  FormControl,
  Checkbox,
  Autocomplete,
  Typography,
  Chip
} from '@mui/material'
import { useTranslation } from '@contexts/translation'
import TextFieldItem from './Form/TextFieldItem'
import { SelectSkeleton } from './Skeletons'
import useChipStyles from '@styles/chip/chip'

const AutocompleteItem = ({
  name,
  label,
  control,
  defaultValue,
  multiple = false,
  errorMessage,
  helperText,
  elements = { items: [], id: '', display: '', disabled: '' }, // { items: array, id: 'id field' (can be 'id', 'pk'...), display: 'display field' (can be 'name', 'display', 'title'...) }
  showCount = false,
  freeSolo = false,
  loading = false,
  skeletonLoading = false,
  variant = 'filled',
  submitOnClick = null,
  groupBy = null,
  required,
  disabled = false,
  helpText = false,
  onClickOption = () => null,
  chipTags,
  data_testid,
  ...props
}) => {
  const { dictionary } = useTranslation()
  const labelId = `${name}-label`
  const classesChip = useChipStyles()

  const idField = elements?.id || 'id'
  const displayField = elements?.display || 'display'

  return (
    <FormControl {...props} variant="filled" margin="normal" fullWidth>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <>
              {!skeletonLoading && (
                <Autocomplete
                  fullWidth
                  data-testid={data_testid}
                  id={labelId}
                  multiple={multiple}
                  disabled={disabled}
                  disableCloseOnSelect={multiple}
                  loading={loading}
                  groupBy={groupBy}
                  getOptionDisabled={option => {
                    if (option[elements?.disabled]) {
                      return true
                    }
                    return false
                  }}
                  value={
                    (freeSolo
                      ? elements.items.find(
                          item => item[idField] === field.value
                        )
                      : field.value) || null
                  }
                  // value={(freeSolo ? defaultValue : field.value) || null}
                  onInputChange={(event, value) => {
                    if (freeSolo && value) field.onChange(value)
                  }}
                  onChange={(event, newValue) => {
                    let _newValue
                    if (multiple) {
                      if (
                        newValue[newValue.length - 1]?.[idField] === 'all' ||
                        newValue[newValue.length - 1] === 'all'
                      ) {
                        _newValue =
                          field.value.length === elements.items.length
                            ? []
                            : elements.items.map(v => v[idField])
                        field.onChange(_newValue)
                      } else {
                        _newValue = newValue.map(v =>
                          v?.[idField] ? v[idField] : v
                        )

                        field.onChange(_newValue)
                      }
                    } else {
                      _newValue = newValue?.[idField] || ''
                      field.onChange(newValue?.[idField] || '')
                    }
                    if (submitOnClick) submitOnClick(_newValue)
                  }}
                  freeSolo={freeSolo}
                  isOptionEqualToValue={(option, value) => {
                    return option[idField].toString() === value.toString()
                  }}
                  options={
                    elements.items
                      ? multiple
                        ? [
                            {
                              [idField]: 'all',
                              [displayField]: dictionary.select_all
                            },
                            ...elements.items
                          ]
                        : elements.items
                      : []
                  }
                  getOptionLabel={option => {
                    if (option[displayField]) return option[displayField] || ''
                    return (
                      elements.items.find(el => el[idField] == option)?.[
                        displayField
                      ] || ''
                    )
                  }}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li
                        {...props}
                        key={props.id}
                        onClick={e => {
                          onClickOption(e, option)
                          props.onClick(e)
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            onClickOption(e, option)
                            props.onClick(e)
                          }
                        }}
                        data-testid="li-autocomplete-96yd609g44"
                      >
                        {multiple && (
                          <Checkbox
                            sx={{ mr: 1 }}
                            checked={
                              option[idField] === 'all'
                                ? field.value.length === elements.items.length
                                : selected
                            }
                            indeterminate={
                              option[idField] === 'all'
                                ? field.value.length < elements.items.length &&
                                  field.value.length > 0
                                : false
                            }
                            data-testid="Checkbox-e6e3c4f1-609c-40fc-ae57-4398d68e4232"
                          />
                        )}

                        {multiple &&
                        option[displayField] === dictionary.select_all ? (
                          <b>{option[displayField]}</b>
                        ) : (
                          option[displayField]
                        )}
                      </li>
                    )
                  }}
                  // {...(showCount && { limitTags: 1 })}
                  renderTags={value => {
                    const element = elements.items.find(el =>
                      multiple
                        ? value.includes(el[idField])
                        : el[idField] == value
                    )
                    const multipleElements = multiple
                      ? elements.items
                          .filter(el => value.includes(el[idField]))
                          .map(el => el[displayField])
                      : undefined
                    return multiple ? (
                      chipTags ? (
                        multipleElements.length > 2 ? (
                          <>
                            {multipleElements.slice(0, 2).map((e, index) => (
                              <Chip
                                key={index}
                                label={e}
                                size="small"
                                className={classesChip.normal}
                                sx={{
                                  marginRight: 1,
                                  paddingY: 2,
                                  marginBottom: 1
                                }}
                              />
                            ))}
                            <Chip
                              label={`+${multipleElements.slice(2).length}`}
                              size="small"
                              className={classesChip.normal}
                              sx={{
                                marginRight: 1,
                                paddingY: 2,
                                marginBottom: 1
                              }}
                            />
                          </>
                        ) : (
                          multipleElements.map((e, index) => (
                            <Chip
                              key={index}
                              label={e}
                              size="small"
                              className={classesChip.normal}
                              sx={{
                                marginRight: 1,
                                paddingY: 2,
                                marginBottom: 1
                              }}
                            />
                          ))
                        )
                      ) : showCount ? (
                        <Typography noWrap variant="subtitle2">
                          {element?.[displayField] +
                            (value.length - 1 > 0
                              ? ` +${value.length - 1}`
                              : '')}
                        </Typography>
                      ) : (
                        <Typography noWrap variant="subtitle2">
                          {multipleElements.join(', ')}
                        </Typography>
                      )
                    ) : (
                      elements.items.find(el => el[idField] == value)?.[
                        displayField
                      ]
                    )
                  }}
                  renderInput={params => (
                    <TextFieldItem
                      {...params}
                      label={label}
                      variant={variant}
                      required={required}
                      data-testid="TextFieldItem-875804cf-c362-4132-8c2e-cd192ef3a934"
                      sx={{ '& .MuiTypography-root': { width: '90px' } }}
                    />
                  )}
                />
              )}

              {skeletonLoading && <SelectSkeleton />}
            </>
          )
        }}
      />
      {helpText && <Typography sx={{ marginTop: 1 }}>{helpText}</Typography>}
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default AutocompleteItem
